footer {
    .footer {

        padding: 16px;
        font-family: 'DINW01', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #c4c4c4;
        display: flex;

        span {
            margin-right: 16px;
        }
    }

    color: #fff;
    background-color: #757575;

    .nav {
        text-decoration: none;
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;

        .item {
            a {
                font-family: 'DINW01', sans-serif;
                color: #c4c4c4;
                text-decoration: underline;
                padding-right: 16px;
                font-style: normal;
                font-size: 14px;

                &:hover {
                    color: #efefef;
                }
            }
        }
    }
}

.container-max-width {
    max-width: 1400px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;

    @media (max-width: 1280px) {
        max-width: 1140px;
    }
}

footer {
    .footer {
        @extend .container-max-width;
    }
}

header {
    .container {
        @extend .container-max-width;
    }
}

.add-new {
    @extend .container-max-width;
    margin-top: 0;

    @media screen and (min-width: 769px) {
        margin-top: 105px;
    }

}