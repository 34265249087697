.items-preview {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;

    @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;

    }

    @media (max-width: 640px) {
        grid-template-columns: 1fr;
    }

    .boerse-item {
        border-radius: 5px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, .35);

        cursor: pointer;
        position: relative;

        padding: 0;
        overflow: hidden;


        &__image-wrapper {
            padding-bottom: 75%;
            position: relative;
            overflow: hidden;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .content {
            padding-bottom: 60px;
            padding-left: 45px;
            padding-right: 45px;
            padding-top: 25px;

        }



        &:hover,
        &:focus,
        &:active {
            .btn {
                opacity: 1;
                transition: opacity 0.3s ease-in-out
            }
        }


        .btn {
            bottom: 10px;
            margin-top: 20px;
            font-style: none;
            text-decoration: none;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            position: absolute;
            bottom: 20px;
        }
    }
}

.btn {
    background-color: #e30513;
    border-color: #e30513;
    color: #fff;
    border: 1px solid #0000;
    border-radius: 0.25rem;
    display: inline-block;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    text-align: center;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        background-color: #be0410;
        border-color: #b1040f;
    }
}

.label {
    position: absolute;
    background: red;
    z-index: 2;
    right: 0;
    bottom: 0;
    color: white;
    width: 100px;
    height: 150px;
    display: flex;
    justify-content: center;
    transform: translate(50%, 50%) rotate(-45deg);
    text-align: center;
    font-size: 14px;
    padding: 10px 50px;
    visibility: hidden;

    &.active {
        visibility: visible;
    }
}