#drop-area {
    border: 2px dashed #ccc;
    border-radius: 20px;
    width: 300px;
    padding: 20px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
        text-align: center;
    }

    input[type="file"] {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
    }
}

#drop-area.hover {
    border-color: #333;
}

.my-form {
    margin: 0 auto;
    width: 100%;
}





.image-upload {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
}

.image-preview {
    width: 300px;
    padding: 0 20px;
    position: relative;


    .wrapper {
        padding-bottom: 75%;
        overflow: hidden;
        position: relative;
        border: 3px solid #ccc;


        div {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}