input[type='submit'],
.button {
  border-radius: 5px;
  padding: 4px 16px;
  border: none;
  font-size: 18px;
  margin-bottom: 15px;
  display: block;
  font-family: 'DINW01';
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  line-height: 1.5;
}