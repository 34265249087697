h1 {
    font-family: 'DINW01';
    font-weight: 600;
    font-size: 32px;
    margin: 0;
    line-height: 2;
    margin-bottom: 10px;
}

#root {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.App {
    height: 100%;
}

h1 {
    font-family: 'DINW01';
    font-weight: 600;
    font-size: 48px;
    margin: 0;
    line-height: 1.5;
}

h3 {
    margin-bottom: 5px;
}

header {
    padding: 15px;
}

header img {
    max-width: 150px;
}

.add-new {
    padding-top: 60px;
    padding-bottom: 100px;
    padding-left: 20px;
    padding-right: 20px;
}

p {
    font-family: 'DINW01';
    margin: 0;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.45;
    color: #333;
    font-size: 21px;
}

a {
    font-weight: 500;
    color: #e30513;
    text-decoration: none;
    font-style: italic;
}

@font-face {
    font-family: 'DINW01';
    src: url('./fonts/DINW01-Bold.woff') format('woff'),
        url('./fonts/DINW01-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'DINW01';
    src: url('./fonts/DINW01-Regular.woff') format('woff'),
        url('./fonts/DINW01-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'DINW01';
    src: url('./fonts/DINW01-Medium.woff') format('woff'),
        url('./fonts/DINW01-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DINW01';
    src: url('./fonts/DINW01-MediumItalic.woff') format('woff'),
        url('./fonts/DINW01-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}

/* App Styles */

h2 {
    font-size: 18px;
    margin-bottom: 5px;
}

h1 {
    font-size: 28px;
    margin-bottom: 10px;
}

p {
    font-size: 18px;
    margin-bottom: 10px;
}

.content {
    max-width: 800px;
}
