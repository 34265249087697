.filter-group {
    // grid
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 60px;

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);

    }


}

.search-input {
    display: flex;
    align-items: center;
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
    max-width: 350px;
    display: flex;
    justify-content: center;


    input {
        background: none;
        border-top: none;
        border-left: none;
        border-radius: 0;
        border-right: none;
        max-width: 350px;
        padding-left: 2px;
        padding-right: 2px;
    }

    svg {
        color: #e30513;
        width: 30px;
        transform: translateX(-100%);
        padding: 3px;
    }
}