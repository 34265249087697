.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;

    /* custom scrollbar */
    ::-webkit-scrollbar {
        width: 20px;
        position: absolute;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #a8bbbf;
    }
}

.modal-content {
    background-color: #fff;
    position: relative;
    width: 800px;
    min-height: 400px;
    overflow: hidden;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
    max-height: 80vh;
    overflow-y: scroll;

    .modal-label-wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 150px;
        overflow: hidden;

    }





    .close-button {
        position: absolute;
        right: 10px;
        border: none;
        top: 10px;
        background: transparent;
        cursor: pointer;
        z-index: 100;
        border-radius: 100%;
        padding: 0;
        transition: ease-in-out 0.2s all;

        &:hover {
            background-color: #f0f0f0;
            transition: ease-in-out 0.2s all;
        }

        img {
            display: block;
            padding: 2px;
        }
    }
}

.modal {
    &__title {
        h1 {
            font-size: 14px;
        }
    }


}

.two-columns {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    // when screen smaller then 840px set flex-direction to column
    @media only screen and (max-width: 838px) {
        flex-direction: column;
    }




    .column {
        position: relative;
        flex-basis: 65%;

        .image-wrapper {
            padding-bottom: 75%;
            position: relative;
            overflow: hidden;

            @media only screen and (max-width: 838px) {
                margin-bottom: 15px;
                margin-top: 15px;
            }

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .image-column {
        flex-basis: 35%;
    }

}