.ausbildung-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1.5;
    color: #333;

    &-item {
        label {
            padding-left: 5px;
            font-size: 18px;
        }
    }

    .left-section {
        display: flex;
        align-items: flex-start;
    }

    input {
        margin-top: 7px;
    }
}

label {
    font-size: 18px;
    color: #333;
}