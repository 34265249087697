header {
    padding: 0;
    margin-top: 5px;
    padding-bottom: 5px;

    position: relative;

    border-bottom: 1px solid #d8d8d8;

    @media screen and (min-width: 769px) {
        position: fixed;
        left: 0;
        right: 0;
        background: white;
        top: 0;
        margin-top: 0;
        padding-top: 5px;
        z-index: 100;
    }


    .container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding-left: 15px;
        padding-right: 15px;

        @media screen and (max-width: 768px) {
            flex-direction: column-reverse;
        }
    }

    .header {
        &--right {
            img {
                height: 90px;
                max-width: unset;
                width: auto;

            }
        }

        &--left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .nav {
        text-decoration: none;
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
    }

    .meta-nav {
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }

        .item {
            margin-right: 16px;
            font-size: 14px;

            &.language {
                margin-right: 0;

                span {
                    cursor: pointer;
                }
            }

            a,
            span {
                text-decoration: none;
                font-style: normal;
                padding: 8px;
                color: #757575;
                display: inline-block;

                &:hover {
                    color: #333;
                }
            }

        }
    }

    .main-nav {
        border-bottom: none;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }

        .item {
            margin-right: 16px;
            font-size: 21px;

            a {
                text-decoration: none;
                font-style: normal;
                padding: 8px;
                color: #333;
                display: inline-block;
                position: relative;

                &::after {
                    pointer-events: none;
                    content: "";
                    position: absolute;
                    display: block;
                    width: calc(100% - 16px);
                    border-bottom: 3px solid #757575;
                    margin-bottom: 0;
                    bottom: 0;
                    color: #757575;
                    opacity: 0;
                    transition: opacity .1s ease-out,
                        border .1s ease-out,
                        margin-bottom .1s ease-out;
                }

                &:hover {
                    color: #333;

                    &::after {
                        margin-bottom: -5px;
                        opacity: 1;
                        transition: opacity .1s ease-out,
                            border .1s ease-out,
                            margin-bottom .1s ease-out;
                    }

                }
            }

        }
    }
}