.preview-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    gap: 20px;


    @media screen and (min-width: 481px) {

        grid-template-columns: repeat(1, 1fr);

    }

    @media screen and (min-width: 769px) {

        grid-template-columns: repeat(2, 1fr);

    }

    @media screen and (min-width: 1025px) {

        grid-template-columns: repeat(3, 1fr);

    }

    @media screen and (min-width: 1281px) {

        grid-template-columns: repeat(4, 1fr);

    }

}

.number-of-games {
    margin-bottom: 15px;
    display: block;
}