.content-wrapper {
  margin-top: 50px;
  border-radius: 5px;
  margin-right: 50px;
  border: 1px solid #02020230;
  min-height: 500px;
  &--header {
    border-bottom: 1px solid #02020230;
    padding: 20px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &--main {
    padding: 20px;
  }
}

.add-button {
  &--wrapper {
    display: flex;
  }
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
}
